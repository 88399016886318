//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BgIcon, BgText, BgImage } from 'Bangul-vue';

import HowToSlidesNavButton from './HowToSlidesNavButton';
import HowToSlidesNavCard from './HowToSlidesNavCard';

export default {
  name: 'HowToSlides',

  components: {
    HowToSlidesNavButton,
    HowToSlidesNavCard,
    BgIcon,
    BgText,
    BgImage,
  },

  props: {
    howTos: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      startIndex: 0,
      currentIndex: 0,
    };
  },

  computed: {
    currentHowTo() {
      return this.howTos[Math.abs(this.currentIndex) % this.howTos.length];
    },
    totalAdvantages() {
      return this.howTos.length;
    },
  },

  methods: {
    nextSlide() {
      this.currentIndex += 1;
    },

    prevSlide() {
      this.currentIndex -= 1;
    },

    goToSlide(index) {
      this.currentIndex = index;
    },

    openImageInNewTab(imageUrl) {
      const openWindow = window.open(imageUrl);

      openWindow.opener = null;
    },
  },
};
