//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BgText } from 'Bangul-vue';

export default {
  name: 'HowToSlidesNavCard',

  components: {
    BgText,
  },

  filters: {
    padNumber(val) {
      const queue = val + 1;
      return String(queue).padStart(2, '0');
    },
  },

  props: {
    currentIndex: {
      type: Number,
      required: true,
    },
    howTos: {
      type: Array,
      required: true,
    },
  },

  watch: {
    currentIndex(value) {
      this.handleScroll();
    },
  },

  mounted() {
    this.handleScroll();
  },

  methods: {
    handleScroll() {
      const element = document.getElementById('navcard');

      const maxScrollLeft = element.scrollWidth - element.clientWidth;

      if (this.currentIndex > 4) {
        element.scrollLeft = maxScrollLeft;
      } else {
        element.scrollLeft = 0;
      }
    },
  },
};
