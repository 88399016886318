//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BgIcon } from 'Bangul-vue';

export default {
  name: 'HowToSlidesNavButton',

  components: {
    BgIcon,
  },

  props: {
    totalAdvantage: {
      type: Number,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
  },

  computed: {
    isIndexMin() {
      return this.currentIndex === 0;
    },
    isIndexMax() {
      return this.currentIndex === this.totalAdvantage - 1;
    },
  },
};
